import React from 'react';
import { Box, useTheme } from '@chakra-ui/react';

type Props = {
  html: string;
};

/**
 * Add common styles to dangerously-set html nodes, for example margin
 * to the bottom of paragraphs.
 */
const HtmlBody: React.FC<Props> = ({ html }) => {
  const { space } = useTheme();

  return (
    <Box
      dangerouslySetInnerHTML={{ __html: html }}
      __css={{
        '& > p': {
          marginBottom: space[6],
          lineHeight: 1.7,
        },
        a: {
          fontWeight: 600,
          color: 'pink.100',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      }}
    />
  );
};

export default HtmlBody;
