import React from 'react';
import { Heading, Text } from '@chakra-ui/react';

type Props = {
  text: string;
}

const PageHeading: React.FC<Props> = ({ text }) => (
  <Heading
    as="h1"
    fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}
    mb={8}
    pb={4}
    borderBottom="8px solid"
  >
    <Text>{text}</Text>
  </Heading>
);

export default PageHeading;
